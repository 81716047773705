import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { of } from 'rxjs';

import { iconCancel, iconSave } from "app/common/utils/icons.utils";

import { catchError } from 'rxjs/operators';
import { CommonValidators } from "app/common/components/form/validators/common.validator";
import { ProfileService } from 'app/services/profile.service';
import { ModalService } from 'app/common/services/modal.service';
import { Company, EntityDefinition, GroupDefinition, Product } from 'app/models/entities.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductService } from 'app/services/product.service';
import { Roles, UserProfile } from 'app/models/profile.models';
import { ToastService } from 'app/common/services/toasts.service';
import { URLUtils } from 'app/services/url-utils';

@Component({
    selector: 'edit-entity-modal',
    templateUrl: './edit-entity-modal.page.html'
})
export class EditEntityModalForm implements OnInit {
	possibileMediaTypes = ['image/png', 'image/jpeg', 'image/gif'];
	iconCancel = iconCancel;
	iconSave = iconSave;
	public entityForm: FormGroup = null;	
	public groups: GroupDefinition[] = [];
	public groupValidity: boolean[] = [];
	data: {
		entity: EntityDefinition,
		product: Product,
		company: Company,
		userProfile: UserProfile
	};
	dataLoaded: boolean = false;

	ngOnInit() {
		let entity;
		if (this.data && this.data.entity) {
			entity = this.data.entity;
		}
		this.initForm(entity);
		this.dataLoaded = true;
	}

    constructor(
		private activeModal: NgbActiveModal,
		private _toastService: ToastService,
		private _fb: FormBuilder,
		private _productService: ProductService,
		private _userProfileService: ProfileService,
		private _modalService: ModalService) {
			
    }

	addGroup = (value?: GroupDefinition) => {
		if (!value) {
			value = new GroupDefinition();
			value.fields = [];
		}
		this.groups.push(value);
		this.groupValidity.push(false);
	}
	removeGroup = (index: number) => {
		this.groups.splice(index,1);
		this.groupValidity.splice(index,1);
	}
	validateGroup = (index: number, valid: boolean) => {
		this.groupValidity[index] = valid;
	}
	public get title() {
		return "editor informazione certificabile";
	}
	private initForm = (initData?: EntityDefinition) => {

		let entityFormDef: any = {
			entityId: [(initData)? initData.entityId: null],
			name: [(initData)? initData.name: null, CommonValidators.required],
			// landingPageUrl: [(initData)?initData.landingPageUrl:URLUtils.getDefaultEntityLandingPagePatternURL(), CommonValidators.websiteFormatValidator],
			// certVerifyerUrl: [(initData)?initData.certVerifyerUrl:URLUtils.getDefaultCertPagePatternURL(), CommonValidators.websiteFormatValidator]
			landingPageUrl: [(initData)?initData.landingPageUrl:URLUtils.getDefaultEntityLandingPagePatternURL()],
			certVerifyerUrl: [(initData)?initData.certVerifyerUrl:URLUtils.getDefaultCertPagePatternURL()],
			landingPageDefinitionId: [(initData)?initData.landingPageDefinitionId:null],
			newLandingPageDefinitionId: [(initData)?initData.newLandingPageDefinitionId:null]
		};

		this.entityForm = this._fb.group(entityFormDef);
		if (initData && initData.groups) {
			initData.groups.forEach((group) => {
				this.addGroup(group);
			})
		}

		this.entityForm.get("name").valueChanges.subscribe((name) => {
			if (name)
			this.entityForm.get("entityId").patchValue(
				(""+name).trim().toLowerCase()
				.replaceAll(" ", "")
				.replaceAll(".", "")
				.replaceAll(",", "")
				.replaceAll("-", "")
				.replaceAll("&", "")
				);
		})
	}

	get isAdmin() {
		return this._userProfileService.isInRole(Roles.ADMIN);
	}

    public save(certData?: boolean) {
		
        if (this.canSaveForm) {
			
			let toSave: EntityDefinition = this.entityForm.value;
			toSave.groups = this.groups;

			if (this.data.product && this.data.product.linkedEntity) {
				let found = false;
				for (let i=0; i < this.data.product.linkedEntity.length && !found; i++) {
					if (this.data.product.linkedEntity[i].entityId == toSave.entityId) {
						found = true;
						this.data.product.linkedEntity[i] = toSave;
					}
				}
				if (!found) this.data.product.linkedEntity.push(toSave);
			}
			else {
				this.data.product.linkedEntity = [toSave];
			}
			
			
            this._productService.saveOrUpdate(this.data.company, this.data.product)
			.pipe(
				catchError(error => {
					this._modalService.showErrors(["Si è verificato un errore durante il salvataggio dei dati."]);
					
					return of(null);
				})
			)
			.subscribe(
				(result) => {
					if (result) this._toastService.showSuccess("Salvataggio avvenuto con successo");
					this.activeModal.close(result);
					
				},
				(error) => {
					this._toastService.showDanger("Si è verificato un errore dusante il lalvataggio dei dati");
				}
			)
        }
		else {
		}
    }

	public get canSaveForm(): boolean {
		return this.entityForm.valid && this.groupValidity.indexOf(false)<0;
	}

	public close = (): void => {
		this.activeModal.close(false);
    }
}


